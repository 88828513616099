@import '../../style/custonVariable';

//New css code begin

@font-face {
  font-family: 'poppinslight';
  src: url('../../../assets/fonts/poppins-light-webfont.woff2') format('woff2'),
    url('../../../assets/fonts/poppins-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsmedium';
  src: url('../../../assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
    url('../../../assets/fonts/poppins-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsregular';
  src: url('../../../assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
    url('../../../assets/fonts/poppins-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.ModalContent{
 iframe{
  display: block !important;
 }
}

//profile grid
.text_primary {
  color: #7266ba !important;
}
// .nFTdashboard{
//   background: #3a843a !important;
//   border: none !important;
//   padding: 1em;
//   border-radius: 1em;
//   text-align: right;
// }

// .nFTdashboard:hover {
//   background: #7cbe7c !important;
//   border: none !important;
// }

.nFTdashboard {
  background: #3a843a !important;
  color: #fff;
  border: none;
  padding: 1em;
  border-radius: 1em;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background 0.3s ease;
}

.nFTdashboard:hover {
  background: #2e6f2e !important;
}

.dashboard_text_success,
.text_success {
  color: #27c24c !important;
}

.text_danger {
  color: #f05050 !important;
}

.text_default {
  color: #444444 !important;
}

.legend_block {
  display: flex;
  justify-content: 'center';
}

.legend {
  list-style: none;
  margin-right: 13px;
  justify-content: 'center';
}

.legend li {
  float: left;
  margin-right: 10px;
}

.legend span {
  float: left;
  width: 34px;
  height: 4px;
  margin: 11px;
}

.listing_box_cnout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: center;
  // justify-items: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.listing_box_cnout div {
  font-size: 15px;
  text-align: left;
  .tailHeading{
    font-size: 13px;
  }

}


.referralLInk {
  display: flex;
}
.darkMode_referralLink{
  display: flex;
}

.graphProfile {
  grid-template-columns: 1fr;
  display: grid;
  padding: 0;
  grid-gap: 30px;
  height: 100%;

  .profilePanel {
    position: relative;
    border-radius: $dashboard-border-radius;
    margin-bottom: $panel-margin-bottom;
    background-color: $panel-bg-color;
    border: 1px solid transparent;
    box-shadow: 0 1px 1px $tials-shadow-color;
  }

  .profileUser {
    display: grid;
    grid-template-columns: 4fr 10fr;
    padding: 22px 0;
    max-width: 100%;

    @media (max-width:768px) {
      grid-template-columns: 1fr;
    }

    .panelBody {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      margin-top: 0px;
      padding: 8px;

      .dashboardProfile {
        border-right: 1px solid rgba(0, 0, 0, 0.14);
        grid-template-columns: 1fr;
        display: grid;
        align-items: center;
        grid-gap: 20px;

        @media (max-width: 991px) {
          border: none;
        }

        .profileAvatar {
          text-align: center;

          img {
            max-width: 90px;
            border-radius: 100%;
            width: 100%;
            vertical-align: middle;
            border: 0;
          }

          .profileName {
            text-transform: none !important;
            margin-bottom: 8px;
            margin-top: 0;
            font-family: $font-mulish;
            font-style: normal;
            text-align: center;

            &.fullName {
              color: #333;
              font-size: 20px;
              padding-top: 10px;
            }

            &.userName {
              font-weight: 400;
              font-size: 15px;
              color: rgb(88, 102, 110);
            }

            &.rankName {
              margin-top: 10px;
              margin-bottom: 10px;
              font-size: 15px;
              color: rgb(88, 102, 110);

              span {
                font-size: 16px;
                // color: #ef8727;
              }
            }
          }

          .viewProfileBtn {
            padding: $button-padding;
            margin-bottom: 0;
            border-radius: 3px;
            letter-spacing: 0.2px;

            background: $dashboard-color-Lblue;
            color: $color-white;
            font-weight: 400;
            border: 1px solid $dashboard-color-Lblue;
            font-size: 14px;
            display: inline-block;

            @media (max-width:991px) {
              margin-top: 0;
            }

            &:hover {
              background: $dashboard-color-blue;
              border: 1px solid $dashboard-color-blue;
            }
          }

          a:hover {
            text-decoration: none !important;
          }
        }
      }
    }

    .promotionTool {
      padding: 0 20px;
      margin: 0;
      border: none;

      .packageRenewal {
        margin-top: 25px;

        @media (max-width:991px) {
          display: grid;
          grid-template-columns: 1fr;
          align-items: center;
          grid-gap: 20px;
          padding: 15px 0px 0px 0px;
        }

        .package {
          vertical-align: top;
          display: inline-block;
          margin-right: 55px;
          align-items: center;
          margin-top: 20px;
          margin-bottom: 30px;

          @media (max-width:991px) {
            display: inline-block;
            margin: 0;
            border: 1px solid #eee;
            text-align: center;
            padding: 10px 10px 0px 10px;
            border-radius: 3px;
            background: #f9f9f9;
          }

          .packageText {
            font-family: $font-mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height */
            text-transform: uppercase;
            color: #58666e;
          }

          .packageData {
            font-family: $font-mulish;
            font-style: normal;
            font-size: 14px;
            line-height: 22px;
            color: #333;
          }

          .moduleButton {
            margin-top: 5px;
            min-height: 38px;

            @media (max-width:991px) {
              padding: 15px 0;
              min-height: 0;
            }

            .renewButton {
              padding: $button-padding;
              margin-bottom: 0;
              border-radius: 3px;
              letter-spacing: 0.2px;

              background: $dashboard-color-blue;
              color: $color-white;
              font-weight: 400;
              border: 1px solid $dashboard-color-blue;
              font-size: 14px;
              display: inline-block;

            }

            a:hover {
              text-decoration: none !important;
            }
          }
        }

        .renew {
          display: inline-block;
          align-items: center;
          margin-top: 20px;
          margin-bottom: 30px;

          @media (max-width:991px) {
            display: inline-block;
            margin: 0;
            border: 1px solid #eee;
            text-align: center;
            padding: 10px 10px 0 10px;
            border-radius: 3px;
            background: #f9f9f9;
          }

          .ExpText {
            font-family: $font-mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            text-transform: uppercase;
            color: #666666;
          }

          .ExpData {
            font-family: $font-mulish;
            font-style: normal;
            font-size: 14px;
            line-height: 22px;
            color: #333333;
          }

          .moduleButton {
            margin-top: 5px;

            @media (max-width:991px) {
              padding: 15px 0;
            }

            .renewButton {
              padding: $button-padding;
              margin-bottom: 0;
              border-radius: 3px;
              letter-spacing: 0.2px;

              background: $dashboard-color-blue;
              color: $color-white;
              font-weight: 400;
              border: 1px solid $dashboard-color-blue;
              font-size: 14px;
              display: inline-block;

            }

            a:hover {
              text-decoration: none !important;
            }
          }
        }
      }

      .borderBotton {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        @media (max-width:991px) {
          display: none;
        }
      }

      .socialLink {
        display: flex;
        margin-top: 25px;

        @media (max-width:991px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          text-align: center;
        }

        @media (max-width:600px) {
          grid-gap: 10px;
        }

        >div {
          @media (max-width:600px) {
            padding: 10px 5px !important;
          }
        }

        .replicaLink {
          display: grid;
          grid-template-columns: 1fr;
          padding: 0px 5px;
          margin-right: 80px;

          @media (max-width:991px) {
            margin-right: 0;
            border: 1px solid #eee;
          }

          .copyLinkReplica {
            cursor: copy;
          }

          button {
            border: none;
            padding: 6px 2px !important;
            font-size: 14px;
            margin-left: -3px;
            margin-right: 2px;
            background: $color-white;
            min-width: 28px !important;

            i {
              color: $replica-icon-color;
            }
          }
        }

        .leadCapture {
          display: grid;
          grid-template-columns: 1fr;
          padding: 0px 5px;

          @media (max-width:991px) {
            border: 1px solid #eee;
          }

          .copyLinkReplica {
            cursor: copy;
          }

          button {
            border: none;
            padding: 6px 2px !important;
            font-size: 14px;
            margin-left: -3px;
            margin-right: 2px;
            background: $color-white;
            min-width: 28px !important;

            i {
              color: $replica-icon-color;
            }
          }
        }
      }



      .moduleStatus {
        display: grid;
        grid-template-columns: 10fr;
        padding: 5px 0;
        background: #dfe5e717;
        min-height: 78px;
        height: auto;
        display: block;

        @media (max-width:991px) {
          text-align: center;
        }

        .packageSection {
          padding-top: 20px;
          display: flex;
          align-items: center;

          @media (max-width:991px) {
            display: block;
          }
        }

        .ExpSection {
          padding-top: 30px;
          display: flex;
          align-items: center;

          @media (max-width:991px) {
            display: block;
          }

          .mr5 {
            margin-right: 5rem;

            @media (max-width:991px) {
              margin-right: 0;
            }
          }
        }


        .modulebuttonsection {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}



//pv panel 
.pvPanel {
  border-radius: $dashboard-border-radius;
  padding: 15px;
  margin-bottom: $panel-margin-bottom;
  background-color: $color-white;
  border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

  .userDetials {
    font-size: 15px;
    display: grid;
    max-width: 100%;

    &::after {
      clear: both;
    }

    .displayGrid {
      display: grid;
    }

    .gridOne {
      grid-template-columns: repeat(1, 1fr);
    }

    .gridTwo {
      grid-template-columns: repeat(2, 1fr);
    }

    .gridThree {
      grid-template-columns: repeat(3, 1fr);

      @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .gridFour {
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .gridFive {
      grid-template-columns: repeat(5, 1fr);

      @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .circleIcon {
      width: 25px;
      height: 25px;
      border-radius: 100%;
      text-align: center;
      margin: -3px 8px 0 10px;
      line-height: 20px;

      &.bgPurple {
        background-color: $box-color-purple;
      }

      &.bgyellow {
        background-color: $box-color-yellow ;
      }

      &.bgskyblue {
        background-color: $box-color-sky-blue;
      }

      &.bglight {
        background: $box-color-light;
      }
    }

    .verticalLine {
      border-right: 1px solid #dedede;

      @media (max-width: 767px) {
        border-right: none;
      }
    }

    .pt25 {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }

    .userHead {
      font-family: $font-mulish;
      font-style: normal;
      font-size: 26px;
      line-height: 30px;
      letter-spacing: 0.03em;
      margin-top: 4px;

      &.Sponsorname {
        font-size: 34px;
        color: #7266ba;
        margin-top: 8px;
      }
    }

    .userTitle {
      color: #666666;
      font-family: $font-mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

//new member
.newMember {
  margin-bottom: $panel-margin-bottom;
  background-color: $color-white;
  border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  padding: 5px 15px 10px;

  h4 {
    margin-bottom: 12px;
    margin-top: 7px;
    margin-left: 5px;
    font-size: 15px;
    font-family: $font-mulish;
    color: rgb(88, 102, 110);
  }

  .scrollHeight {
    height: 250px !important;

    .scrollDiv {
      height: auto;

      .listGroupItem {
        position: relative;
        display: block;
        // padding: 0.75rem 1.25rem;
        background-color: #fff;

        .listItem {
          border-top: 1px solid #e5e5e580;
          padding: 10px 10px;

          .avatarImg {
            display: inline-block;
            width: 40px;
            position: relative;
            white-space: nowrap;
            border-radius: 500px;

            img {
              width: 100%;
            }

            i {
              margin: 1px;
              position: absolute;
              top: 0;
              left: 0;
              width: 10px;
              height: 10px;
              margin: 2px;
              border-style: solid;
              border-width: 2px;
              border-radius: 100%;

              &.on {
                background-color: #27c24c;
              }

              &.bottom {
                top: auto;
                right: 0;
                bottom: 0;
                left: auto;
              }

              &.bWhite {
                border-color: $color-white;
              }
            }
          }

          .memberFullName {
            font-size: 16px !important;
            font-weight: 500;
            color: #595c5d;
          }

          .memberUserName {
            color: #8176c2;
            font-size: 14px !important;
          }

          .memberPackage {
            font-size: 17px;
            color: #595c5d;
            font-weight: 500;

            .memberSmallText {
              font-weight: 300;
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .NodataImage {
        text-align: center;

        img {
          width: 50%;
        }

        .noDataText {
          color: #c0c8cf;
          font-size: 19px;
          margin-top: 0;
        }
      }
    }
  }
}

.chartPanel {
  border-color: transparent;
  border-width: 0;
  display: table;
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  border-radius: 2px;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

  .colWrapper {
    display: table-cell;
    float: none;
    height: 100%;
    vertical-align: top;
    padding: 15px;
    max-height: 100% !important;

    .chartCanvas {
      height: 270px
    }
  }
}

//rank panel
.rankPanel {
  padding: 15px;
  border-radius: 2px;
  margin-bottom: 20px;
  background-color: $color-white;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

  .currentNext {
    display: grid;

    &.twoCol {
      grid-template-columns: 1fr 1fr;

      @media (max-width:991px) {
        grid-template-columns: 1fr;
      }

      .currentRank {
        border-right: 1px solid #e4e4e4;
        padding-right: 20px;

        @media (max-width:991px) {
          border-right: none;
          padding-right: 0;
        }

        .currentRankText {
          color: #8278c2;
        }

        .currentRankSection {
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: 50px;

          .currentContainer {
            border-top: 1px solid #eee;
            margin-top: -1px;
            padding-top: 10px;
            padding-bottom: 10px;
            min-height: 90px;
            display: grid;
            grid-template-columns: 1fr 2fr 2fr;
            align-items: center;

            @media (max-width:991px) {
              grid-template-columns: 1fr;
            }

            &:first-child {
              border-top: 0 solid #eee;
            }

            .rankCount {
              display: grid;
              grid-template-columns: 1fr 1fr;
            }

            .text_info_count {
              margin-top: 0px !important;
            }

            .rankText {
              font-size: 14px;
              color: #444444;
              text-align: center;

              @media (max-width:991px) {
                text-align: center;
              }

              .rankSubtitle {
                color: $color-primary;
              }
            }

            .required {
              background-color: #e1e9fc;
              margin-right: 5px;
              border-radius: $dashboard-border-radius;
              align-items: center;

              h4 {
                margin-right: 5px;

              }
            }

            .achieved {
              background-color: #e0e8de;
              border-radius: $dashboard-border-radius;
              align-items: center;

              h4 {
                margin-right: 5px;

              }
            }
          }
        }
      }

      .nextRank {
        padding-left: 20px;

        @media (max-width:991px) {
          padding-left: 0;
        }

        .nextRankText {
          color: #8278c2;
        }

        .nextRankSection {
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: 50px;

          .currentContainer {
            border-top: 1px solid #eee;
            margin-top: -1px;
            padding-top: 10px;
            padding-bottom: 10px;
            min-height: 90px;
            display: grid;
            grid-template-columns: 1fr 2fr 2fr;
            align-items: center;
            grid-column-gap: 50px;

            @media (max-width:991px) {
              grid-template-columns: 1fr;
            }

            .rankText {
              @media (max-width:991px) {
                text-align: center;
              }

              .rankSubtitle {
                color: $color-primary;
              }
            }

            .text_info_count {
              margin-top: 0px !important;
            }

            .required {
              background-color: #e1e9fc;
              margin-right: 5px;
              border-radius: $dashboard-border-radius;
              align-items: center;

              h4 {
                margin-right: 5px;

              }
            }

            .achieved {
              background-color: #e0e8de;
              border-radius: $dashboard-border-radius;
              align-items: center;

              h4 {
                margin-right: 5px;

              }
            }

            &:first-child {
              border-top: 0 solid #eee;
            }

            .rankCount {
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
          }
        }
      }
    }
  }

  .achivementStatus {
    text-align: center;

    span {
      line-height: 1;
      margin: 20px auto 5px;
      padding: 12px 25px;
      display: inline-block;
      min-width: 60%;
      color: #58666e;
      background-color: #edf1f2;
    }
  }
}

//dashboard tab
.dashboardTabSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width:1200px) {
    grid-template-columns: 1fr;
  }

  @media (max-width:440px) {
    display: block;
  }

  .dashboardTab {
    min-height: 315px;
    border-radius: 2px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    font-weight: 400;
    font-size: 15px;

    .responsiveTab {
      display: none;

      @media (max-width:767px) {
        display: none;
      }
    }

    .Wrapper {
      padding: 15px;

      .font_thin {
        font-weight: 500;
        color: #5a5243
      }
    }

    .TabPanel {
      border: none;
      padding: 0 1em;

      @media (max-width:767px) {
        overflow: scroll;
      }
    }

    .tabs {
      border-bottom: none;

      a {
        color: #5a5243;
      }
    }

    .tabItems {
      font-size: 15px;
      padding: 5px 10px 5px 0;
      margin-left: 17px;
    }

    .tabContent {
      display: block;
      background: $color-white;
      padding: 15px 0px;
      text-align: left;
      width: 100%;

      @media (max-width:767px) {
        overflow: scroll;
      }

      .table {
        margin-bottom: 0;
        width: 100%;
        max-width: 100%;
        background-color: transparent;

        tbody>tr>td:first-child {
          padding-left: 0;
        }

        tbody>tr>td {
          border-bottom: 1px solid #eaeff0;
          border-top: 0 solid #ddd;
          text-align: left;
          vertical-align: middle;
          padding: 10px 15px;
          line-height: 2.7;
          font-size: 15px;
          color: #444444;

          @media (max-width:767px) {
            padding: 10px 15px;
          }

          .btnInfo {
            color: #fff !important;
            background-color: #23b7e5;
            border-color: #23b7e5;
            border-radius: 0%;
            font-size: 13px;
          }
        }
      }
    }

    .NodataImage {
      text-align: center;

      img {
        width: 60%;
      }

      .noDataText {
        color: #c0c8cf;
        font-size: 19px;
        margin-top: 0;
      }
    }
  }

  .teamperfomance {
    min-height: 315px;
    border-radius: 2px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    font-weight: 400;
    font-size: 14px;

    .NodataImage {
      text-align: center;

      img {
        width: 60%;
      }

      .noDataText {
        color: #c0c8cf;
        font-size: 19px;
        margin-top: 0;
      }
    }

    .Wrapper {
      padding: 15px;
    }

    .tabs {
      border-bottom: none;

      a {
        color: #5a5243;
      }
    }

    .tabItems {
      font-size: 15px;
      padding: 5px 10px 5px 0;
      margin-left: 17px;
    }

    .TabPanel {
      border: none;
      padding: 0 1em;
      display: block;
      background: $color-white;
      padding: 15px;
      text-align: left;
      width: 100%;

      @media (max-width:767px) {
        overflow: scroll;
      }

      .table {
        margin-bottom: 0;
        width: 100%;
        max-width: 100%;
        background-color: transparent;

        tbody>tr>td:first-child {
          padding-left: 0;
        }

        tbody>tr>td {
          border-bottom: 1px solid #eaeff0;
          border-top: 0 solid #ddd;
          text-align: left;
          vertical-align: middle;
          padding: 5px 15px;

          &.topRecruters {
            padding: 0;
          }

          &.gridOne {
            display: grid;
            grid-template-columns: 1fr;

            .avatarImg {
              min-height: 26px;
              display: inline-block;
              min-width: 50px;
              margin-top: 7px;
              margin-bottom: 7px;
              width: 30px;
              position: relative;
              display: block;
              white-space: nowrap;
              border-radius: 500px;

              img {
                max-width: 100%;
                vertical-align: middle;
                width: 46px;
                border-radius: 500px;
                height: 46px;
              }

              i {
                margin: 1px;
                position: absolute;
                top: 0;
                left: 0;
                width: 10px;
                height: 10px;
                margin: 2px;
                border-style: solid;
                border-width: 2px;
                border-radius: 100%;

                &.on {
                  background-color: #27c24c;
                }

                &.bottom {
                  top: auto;
                  right: 0;
                  bottom: 0;
                  left: auto;
                }

                &.bWhite {
                  border-color: $color-white;
                }
              }
            }

            .memberFullName {
              font-size: 15px;
              font-weight: 500;
              color: #595c5d;
            }

            .username {
              min-width: unset;
              margin: 0px;
              min-height: 26px;
              display: inline-block;
              min-width: 50px;
              margin-top: 0px;
              margin-bottom: 7px;
            }

            .memberPackage {
              margin: auto;
              position: absolute;
              bottom: 20%;
              right: 0;

              .memberPackageAmount {
                font-size: 17px;
                color: #595c5d;
                font-weight: 500;
              }
            }
          }

          .btnInfo {
            color: #fff !important;
            background-color: #23b7e5;
            border-color: #23b7e5;
            border-radius: 0%;
          }
        }
      }
    }
  }
}

.TilesfilterIcon {
  position: absolute;
  // background: red;
  right: 15px;
  top: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid rgb(236, 225, 225);
  z-index: 2;
  cursor: pointer;
}

.box {
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 991px) {
    padding-right: 0 !important;
  }

  &::after {
    content: "";
    position: absolute;
    width: $dashboard-box-width;
    height: $dashboard-box-height;
    opacity: $dashboard-box-opacity;
    border-radius: $dashboard-box-border-radius;
    z-index: 1;
    display: block;
    top: -25%;
    left: -16%;

    @media screen and (min-width: 1600px) {
      top: -14%;
      left: -22%;
    }

    @media screen and (max-width: 767px) and (min-width: 490px) {
      left: -10%;
    }

    @media screen and (max-width: 991px) and (min-width: 768px) {
      left: -7%;
    }

  }

  &::before {
    content: "";
    position: absolute;
    width: $dashboard-box-width;
    height: $dashboard-box-height;
    display: block;
    z-index: 1;
    border-radius: $dashboard-box-border-radius;
    opacity: $dashboard-box-opacity;
    top: -40%;
    left: -10%;

    @media screen and (min-width: 1600px) {
      left: -12%;
      width: 132px;
      height: 120px;
    }

    @media screen and (max-width: 600px) {
      left: -18%;
      top: -86%;
      width: 170px;
      height: 150px;
    }

    @media screen and (max-width: 991px) and (min-width: 490px) {
      left: -3%;
    }
  }

  &.box0 {
    &::after {
      background-color: $box-color-purple;
    }

    &::before {
      background-color: $box-color-purple;
    }

    .panelAmount {
      span {
        color: #9C67CE !important;
      }
    }

  }

  &.box1 {
    &::after {
      background-color: $box-color-sky-blue;
    }

    &::before {
      background-color: $box-color-sky-blue;
    }

    .panelAmount {
      span {
        color: #428FA8 !important;
      }
    }
  }

  &.box2 {
    &::after {
      background-color: $box-color-light;
    }

    &::before {
      background-color: $box-color-light;
    }

    .panelAmount {
      span {
        color: #3F80B0 !important;
      }
    }
  }

  &.box3 {
    &::after {
      background-color: $box-color-yellow;
    }

    &::before {
      background-color: $box-color-yellow;
    }

    .panelAmount {
      span {
        color: #BDAD28 !important;
      }
    }
  }

  .MainPanel {
    box-shadow: $panel-shadow  !important;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    cursor: pointer;
    padding-top: 40px;
    padding-bottom: 40px;

    @media screen and (min-width: 1600px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    @media screen and (max-width: 991px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .paneltext {
      font-family: $font-mulish ;
      font-style: normal;
      font-size: 15px;
      line-height: 18px;
      color: #1c2b36;
    }

    .panelAmount {
      span {
        font-weight: 500;
        font-size: 26px;
        line-height: 39px;
      }
    }
  }
}

.TabClass {
  background: transparent;
  border-style: none;
  border-color: transparent;
  border-width: 0;

  @media (max-width:767px) {
    border: none;
  }
}

.rank_text {
  padding: 5px 5px 5px 15px;

  h4 {
    margin-bottom: 0px;
    font-size: 1.4rem;
  }

  h5 {
    margin-bottom: 0px;
    font-size: 0.95rem
  }
}





///new code    /// latest code 


@font-face {
  font-family: 'poppinslight';
  src: url('../../../assets/fonts/poppins-light-webfont.woff2') format('woff2'),
    url('../../../assets/fonts/poppins-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsmedium';
  src: url('../../../assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
    url('../../../assets/fonts/poppins-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'poppinsregular';
  src: url('../../../assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
    url('../../../assets/fonts/poppins-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  margin: 0;
  padding: 0;
  background-image: url(../../../assets/images/dashboard/main-background.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100vh;
  font-family: 'poppinsregular';
}

.border-0 {
  border: 0 !important;
}

.main_contant_sec {
  width: 100%;
  height: auto;
  float: left;

  .topbar_section {
    width: 100%;
    height: auto;
    float: left;
    padding: 10px 15px;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.03);

    .logo_sec {
      width: 200px;
      height: auto;
      float: left;

      .logo_cn {
        width: 100px;
        height: auto;
        float: left;

        img {
          width: 100%;
        }
      }

      .menubar-toggle-btn {
        width: 30px;
        height: 30px;
        cursor: pointer;
        float: left;
        position: relative;
        top: 13px;
        margin-right: 10px;

        span {
          width: 25px;
          height: 2px;
          background-color: #000000;
          float: left;

          &::after {
            width: 50%;
            height: 2px;
            position: absolute;
            bottom: 10px;
            left: 0;
            background-color: #000000;
            content: '';
          }

          &::before {
            width: 50%;
            height: 2px;
            position: absolute;
            bottom: 19px;
            left: 0;
            background-color: #000000;
            content: '';
          }
        }
      }

    }


    .top_notification_user_sec {
      width: auto;
      float: right;
      height: 30px;
      margin-top: 12px;

      .top_notification_sec {
        width: 120px;
        height: auto;
        float: left;
        padding-right: 20px;

        .top_notification_ico {
          width: 25px;
          height: 50px;
          float: left;
          color: rgba(0, 0, 0, 0.6);
          font-size: 15px;
          position: relative;
          margin-right: 25%;
          margin-top: 3px;

          img {
            width: 100%;
          }

          .notification_count {
            width: 20px;
            height: 20px;
            position: absolute;
            float: left;
            background-color: #f00;
            text-align: center;
            line-height: 20px;
            color: #fff;
            border-radius: 50px;
            right: -5px;
            top: -7px;
          }

          // &:hover .notification_msg_box{display: block;}
        }

        .notification_msg_box {
          width: 250px;
          max-height: 300px;
          position: absolute;
          right: 0;
          top: 45px;
          background-color: #fff;
          box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
          padding: 10px;
          border-radius: 5px;
          display: none;
          z-index: 9;

          .notification_msg_box_head {
            width: 100%;
            height: auto;
            float: left;
            padding-bottom: 10px;
            border-bottom: 1px #e5e5e5 solid;
            font-weight: bold;
            font-size: 15px;
            color: #242424;
          }

          .notification_msg_box_cnt {
            width: 100%;
            height: auto;
            float: left;
            max-height: 250px;
            overflow: auto;
            padding-top: 10px;

            .notification_msg_box_cnt_row {
              width: 100%;
              height: auto;
              float: left;
              margin-bottom: 10px;
              font-size: 14px;
              color: #646464;

              span {
                width: 100%;
                height: auto;
                float: left;
                font-size: 11px;
                color: rgb(173, 173, 173);
              }
            }
          }
        }

        .tp_msg_btn.show {
          .notification_msg_box {
            display: block;
          }
        }

        .tp_not_btn.show {
          .notification_msg_box {
            display: block;
          }
        }

      }

      .top_user_acc_sec {
        width: auto;
        max-width: 220px;
        height: auto;
        float: left;
        color: #242424;
        font-size: 14px;
        line-height: 30px;

        button {
          border: 0;
          background-color: transparent;
        }

        span {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          float: left;
          background-color: #777777;
          text-align: center;
          line-height: 30px;
          color: #fff;
          font-size: 18px;
          margin-right: 5px;
        }

        .dropdownmenu {
          right: 0;
          top: 47px;

          .dropdownitem {
            padding: 0rem 1rem;
          }
        }
      }
    }

    .dropdown-toggle_1.show .dropdownmenu {
      display: block;
    }

  }

  .contant_sec {
    width: 100%;
    height: auto;
    float: left;

    .cntr_main_cnt_sc {
      width: 100%;
      height: auto;
      float: left;
      position: relative;
      //  padding-left: 210px;

      .left_navigation_sc {
        width: 200px;
        height: auto;
        float: left;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;

        ul {
          padding: 0;
          padding-top: 20px;

          li {
            list-style: none;
            text-align: center;
            position: relative;
            margin-bottom: 15px;
            transition: 0.2s ease;

            a {
              color: #666;
              font-size: 15px;
              text-decoration: none;
              transition: 0.2s ease;

              &:hover {
                color: #000;
              }

              .material-icons-outlined {
                position: absolute;
                left: 13px;
                top: 9px;
              }
            }

            strong {
              position: static;
              left: 70px;
              top: 0px;
              display: block;
              background-color: transparent;
              padding: 10px;
              color: #666;
              height: 40px;
              line-height: 20px;
              width: 100%;
              text-align: left;
              margin-left: 36px;
              font-weight: normal;
            }

            &:hover strong {
              display: block;
              color: #000;
            }

            &:hover .submenu {
              display: block;
              padding: 10px;
              opacity: 1;
              height: auto;
              pointer-events: inherit;
            }

            .arrow_mn {
              position: absolute;
              right: 20px;
              font-size: 16px;
              top: 11px;
            }

            .submenu {
              padding: 0;
              transition: opacity 1s ease-out;
              opacity: 0;
              height: 0;
              overflow: hidden;
              pointer-events: none;
              //  position: absolute;
              //  right: -90px;
              //  top: 0;
              background-color: transparent;
              z-index: 9;

              li {
                margin-bottom: 5px;
                text-align: left;
                padding-left: 10px;

                a {
                  text-decoration: none;

                  &:hover {
                    color: #000;
                  }
                }
              }
            }

          }

          .active {
            a {
              margin-bottom: 0px;
              display: inline-block;
              padding-top: 13px;

              span {
                width: 40px;
                height: 40px;
                background: #363636;
                border-radius: 15px;
                display: inline-block;
                line-height: 40px;
                color: #fff;
                position: absolute;

                &:after {
                  position: absolute;
                  width: 46px;
                  height: 26px;
                  left: -2px;
                  top: 26px;
                  content: '';
                  background: #363636;
                  /* opacity: 0.8; */
                  -webkit-filter: blur(12px);
                  filter: blur(12px);
                  border-radius: 15px;
                }
              }
            }



          }

        }
      }


      .dashboard_main_cnt {
        width: 100%;
        height: auto;
        float: left;
        padding: 20px 0px;

        .dashboard_pannel_box {
          width: 100%;
          height: 90%;
          float: left;
          padding: 20px;
          background: #FFFFFF;
          box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.03);
          border-radius: 10px;
          position: relative;
          margin-bottom: 15px;

          &:hover {
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.18);
          }

          .profile_main_sec {
            width: 100%;
            height: 100%;
            float: left;
            padding-left: 130px;
            border-right: 1px #e5e5e5 solid;
            position: relative;

            .profile_main_img_bx {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              background-color: #fff;
              border: 5px solid #FFFFFF;
              box-sizing: border-box;
              filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
              overflow: hidden;
              position: absolute;
              left: 10px;
              top: 0px;

              img {
                width: 100%;
                flex-shrink: 0;
                min-width: 100%;
                min-height: 100%;
              }
            }

            .profile_main_txt_sc {
              width: 100%;
              height: auto;
              float: left;

              strong {
                width: 100%;
                height: auto;
                float: left;
                font-size: 22px;
                color: #000;
                margin-bottom: 5px;
                overflow-wrap: break-word;
              }

              span {
                width: 100%;
                height: auto;
                float: left;
                font-size: 14px;
                color: #868686;
                margin-bottom: 5px;
              }

              .view_prfl_btn {
                width: 120px;
                // height: 30px;
                display: inline-block;
                filter: drop-shadow(0px 5px 14px rgba(86, 85, 88, 0.2));
                border-radius: 60px;
                border: 0.5px solid #bebebe;
                text-align: center;
                line-height: 28px;
                font-size: 14px;
                color: #6C6C6C;
                text-decoration: none;
                margin-top: 8px;
                transition: 0.2s ease;

                &:hover {
                  background-color: #363636;
                  color: #fff;
                }
              }
            }
          }

          .profile_soc_share_sec {
            width: 100%;
            height: 100%;
            float: left;
            border-right: 1px #e5e5e5 solid;
            position: relative;
            padding-top: 8px;
            padding-bottom: 15px;

            .profilesoc_share_bx {
              width: 50%;
              height: auto;
              float: left;
              margin-top: 10px;

              .profilesoc_share_bx_hd {
                width: 100%;
                height: auto;
                float: left;
                font-size: 14px;
                color: #5E5E5E;
              }

              .profilesoc_share_bx_row {
                width: 100%;
                height: auto;
                float: left;
                margin-top: 10px;

                a {
                  color: black;
                }

                .profilesoc_share_bx_ico {
                  width: 42px;
                  height: 40px;
                  margin-left: 5px;
                  line-height: 30px;
                  margin-right: 2%;
                  text-align: center;
                  float: left;
                  background: #FFFFFF;
                  border-radius: 50%;
                  padding-top: 5px;
                  transition: 0.2s ease;
                  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);

                  &:hover {
                    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.35);
                  }

                  img {
                    width: 15px;
                  }

                }
                .Darkmode_profilesoc_share_bx_ico {
                  width: 42px;
                  height: 40px;
                  margin-left: 5px;
                  line-height: 30px;
                  margin-right: 2%;
                  text-align: center;
                  float: left;
                  background: #FFFFFF;
                  border-radius: 50%;
                  padding-top: 5px;
                  transition: 0.2s ease;
                  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);

                  &:hover {
                    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.35);
                  }

                  img {
                    width: 15px;
                  }

                }
              }
            }
          }

          .profile_other_box {
            width: 100%;
            height: 100%;
            float: left;
            border-right: 1px #e5e5e5 solid;
            padding-right: 10px;
            position: relative;

            .profile_other_box_cnt {
              width: 100%;
              height: auto;
              float: left;
              color: #868686;
              font-size: 13px;
              line-height: 20px;
              margin-bottom: 10px;

              strong {
                width: 100%;
                height: auto;
                float: left;
                color: #243040;
                font-size: 16px;
              }
            }
          }



        }

        /**panel*/


        .dashboard_boxs_listing_sponcer_sec {
          width: 100%;
          height: auto;
          // float: left;

          .carbonTablecontainer {
            margin-top: 1em;

            .card {
              display: block !important;
            }
          }

          .carbonTable {
            font-weight: bold;

            th {
              color: #2e96b5;
              // font-weight: bolder;
              cursor: pointer;
              text-align: center;

            }
            td {
              text-align: center;
            }
           
          }

          .lst_box_quick_select {
            width: 100%;
            height: auto;
            float: left;

            .lst_box_quick_select_btn {
              position: absolute;
              width: 4px;
              font-size: 22px;
              color: #665f5f;
              right: 23px;
              top: 3px;
              line-height: 6px;
              word-break: break-word;
              padding: 6px 15px;
              background-color: transparent;
              border: 0;
              z-index: 8;
              // transform: rotate(-91deg);

            }

          }

          .show {
            .dropdownmenu {
              display: block !important;
              right: 10px;
              top: 33px;
            }
          }

          .boxes_lst_sec {
            grid-template-columns: repeat(auto-fit, minmax(15%, 1fr));
            display: grid;
            height: 100%;
            gap: 1%;

            .dashboard_pannel_box {
              padding: 0;
            }
            .list_boxes_main{

              &:first-child{
                .dashboard_pannel_box{
                  .listing_box_sec{
                    display: flex;
                    gap: 10px;
                    width: 100%;
                  }
                }
              }

              &:nth-child(2){
                .dashboard_pannel_box{
                  .listing_box_sec{
                    display: flex;
                    gap: 10px;
                    width: 100%;
                  }
                }
              }

              &:nth-child(3){
                .dashboard_pannel_box{
                  .listing_box_sec{
                    display: flex;
                    gap: 10px;
                  }
                }
              }


            }

          }

          .list_boxes_main {
            width: 100%;
            height: auto;
            float: left;
            // cursor: pointer;
           
          }

          // .list_boxes_main:hover {
          //   box-shadow: 0 0 11px rgba(33, 33, 33, .2);
          // }


          .dashboard_pannel_box {
            // box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.07);
            height: 90%;
            border-radius: 10px;
          }

          .listing_box_sec {
            width: 100%;
            height: 100%;
            float: left;
            // min-height: 230px;
            padding: 20px;
            overflow: hidden;
            position: relative;

            &::after {
              width: 150px;
              height: 150px;
              position: absolute;
              right: -70px;
              bottom: -70px;
              content: '';
              background: #FFE3E3;
              opacity: 0.6;
              border-radius: 120px;
              display: none;
            }

            .listing_box_ico_rnd {
              width: 80px;
              height: 70px;
              float: left;
              background: #DF3B3B;
              border-radius: 20px;
              margin-bottom: 10px;
              text-align: center;
              line-height: 60px;
              position: relative;

              &::after {
                width: 50px;
                height: 50px;
                position: absolute;
                left: 20px;
                bottom: -23px;
                content: '';
                background: #E75D5D;
                opacity: 0.6;
                -webkit-filter: blur(24px);
                filter: blur(19px);
                border-radius: 5px;
              }

              img {
                width: 50%;
              }
            }

            .listing_box_head {
              width: 100%;
              height: auto;
              float: left;
              color: #535353;
              font-size: 16px;
              margin-top: 10px;
              line-height: 22px;
              position: relative;
              z-index: 1;
              margin-bottom: 10px;
              text-align: left;
            }

            .listing_box_cnout {

              width: 100%;
              height: auto;
              float: left;
              font-weight: bold;
              font-size: 21px;
              font-family: 'poppinsmedium';
              color: #DF3B3B;
              // position: relative;
              position: static;
              bottom: 10%;
              z-index: 1;
              // &::after{
              //   width: 3px;
              //   height: 20px;
              //   background-color: #DF3B3B;
              //   content: '';
              //   position: absolute;
              //   left: -20px;
              //   top: 6px
              // }
            }


          }

          .lst_box2 {
            .listing_box_ico_rnd {
              background: #68CADF;

              &::after {
                background: #6ac8dd;
              }
            }

            // .listing_box_sec {
            //   &::after {
            //     background: #D8F8FF;
            //   }
            // }

            .listing_box_cnout {
              color: #258da5;

              &::after {
                background-color: #68CADF;
              }
            }
          }

          .lst_box3 {
            .listing_box_ico_rnd {
              background: #70A9FF;

              &::after {
                background: #8AB9FF;
              }
            }

            .listing_box_sec {
              &::after {
                background: #DFECFF;
              }
            }

            .listing_box_cnout {
              color: #3a76cf;

              &::after {
                background-color: #70A9FF;
              }
            }
          }

          .lst_box4 {
            .listing_box_ico_rnd {
              background: #F273FD;

              &::after {
                background: #F273FD;
              }
            }

            .listing_box_sec {
              &::after {
                background: #FDE6FF;
              }
            }

            .listing_box_cnout {
              color: #F273FD;

              &::after {
                background-color: #F273FD;
              }
            }
          }


          .sponcer_sec {
            width: 100%;
            height: 100%;
            float: left;

            .sponcer_top_name_sec {
              width: 100%;
              height: auto;
              float: left;
              position: relative;
              padding-right: 50px;

              .sponcer_top_ico {
                width: 50px;
                height: auto;
                position: absolute;
                right: 10px;
                top: 10px;
              }

              .sponcer_top_name_txt {
                width: 100%;
                height: auto;
                float: left;
                color: #0B5C97;
                font-size: 22px;
                margin-top: 5px;

                span {
                  width: 100%;
                  height: auto;
                  float: left;
                  color: #868686;
                  font-size: 15px;
                  margin-top: 3px;
                }
              }
            }

            .spouncer_cnt_row {
              width: 100%;
              height: auto;
              float: left;
              margin-top: 40px;
              display: flex;
              flex-wrap: wrap;
              align-items: self-end;

              .spouncer_cnt_boxes {
                width: 19%;
                height: auto;
                float: left;
                margin-right: 1%;
                color: #0E7FB8;
                font-size: 13px;
                margin-bottom: 5px;

                strong {
                  width: 100%;
                  height: auto;
                  float: left;
                  color: #243040;
                  font-size: 18px;
                }
              }
            }
          }


        }

        .dash_member_join_rank_sec {
          width: 100%;
          height: auto;
          float: left;
          margin-top: 20px;
          grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
          gap: 1%;
          display: grid;

          .dashboard_new_member_sec {
            width: 100%;
            height: auto;
            float: left;

            .dashboard_new_member_head {
              width: 100%;
              height: auto;
              float: left;
              color: #2D2D2D;
              font-size: 17px;
              padding-bottom: 15px;
            }

            .dashboard_new_member_cnt_scrl {
              width: 100%;
              height: 300px;
              overflow: auto;
              float: left;
              padding-right: 18px;


              &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #F5F5F5;
              }

              &::-webkit-scrollbar {
                width: 10px;
                background-color: #F5F5F5;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #000000;
                border: 2px solid #555555;
              }
            }

            .dashboard_new_member_row {
              width: 100%;
              height: auto;
              float: left;
              margin-bottom: 8px;
              border-bottom: 1px solid rgba(155, 153, 145, 0.2);
              padding-bottom: 5px;

              &:last-child {
                border-bottom: 0;
              }

              .dashboard_new_member_sub_row {
                width: 100%;
                height: auto;
                float: left;

                .dashboard_new_member_name {
                  width: 70%;
                  height: auto;
                  float: left;
                  color: #6D617C;
                  font-size: 16px;
                  margin-bottom: 3px;

                }

                .dashboard_new_member_amnt {
                  width: 30%;
                  height: auto;
                  float: right;
                  font-size: 16px;
                  color: #656565;
                  text-align: right;
                }

                .dashboard_new_member_id_date {
                  width: 70%;
                  height: auto;
                  float: left;
                  font-size: 13px;
                  color: #888888;

                }

                .dashboard_new_member_date_sc {
                  width: 30%;
                  height: auto;
                  float: right;
                  font-size: 13px;
                  color: #888888;
                  text-align: right;
                }
              }

            }
          }

          .dashboard_joining_sec {
            width: 100%;
            height: auto;
            float: left;

            .dashboard_newsec_head {
              width: 100%;
              height: auto;
              float: left;
              color: #2D2D2D;
              font-size: 17px;
              padding-bottom: 15px;
            }

            .dashboard_newsec_grph_sec {
              width: 100%;
              height: 300px;
              float: left;
            }
          }

          .dashboard_rank_sec {
            width: 100%;
            height: auto;
            float: left;

            .dashboard_rank_scrl_sec {
              width: 100%;
              height: 350px;
              overflow: auto;
              float: left;

              .dashboard_rank_row {
                width: 100%;
                height: auto;
                float: left;
                margin-bottom: 5px;
                padding-bottom: 18px;
                border-bottom: 1px solid rgba(229, 229, 229, 0.2);

                .dashboard_rank_row_head {
                  width: 100%;
                  height: auto;
                  float: left;
                  font-size: 14px;
                  margin-bottom: -10px;

                  span {
                    color: #2855BF;
                  }
                }

                .dashboard_rank {
                  width: 70%;
                  height: auto;
                  float: left;
                  position: relative;
                  padding-left: 40px;
                  margin-top: 18px;

                  .dashboard_rank_ico {
                    width: 35px;
                    height: 35px;
                    border-radius: 10px;
                    background: linear-gradient(180deg, #75df68 0%, #2D70BE 100%);
                    text-align: center;
                    line-height: 30px;
                    position: absolute;
                    left: 0;
                    top: 2px;
                  }

                  .dashboard_rank_hd {
                    width: 100%;
                    height: auto;
                    float: left;
                    font-size: 13px;
                    color: #868686;
                    margin-bottom: 5px;
                  }

                  .dashboard_rank_prg_bar {
                    width: 100%;
                    height: auto;
                    float: left;
                    font-size: 13px;
                    color: #868686;
                    padding-right: 30px;
                    position: relative;

                    .dashboard_rank_prg_bar_cnt {
                      width: 25px;
                      height: auto;
                      position: absolute;
                      right: 10px;
                      color: #444444;
                      font-size: 13px;
                      top: -6px;
                    }

                    .progress {
                      width: 94%;
                      height: 5px;
                      border-radius: 23px;
                    }

                    .progress_wrap {
                      background: #ddd;
                      margin: 0px 0;
                      overflow: hidden;
                      position: relative;

                      .progress_bar {
                        background: #004073;
                        left: 0;
                        position: absolute;
                        top: 0;
                      }
                    }

                  }
                }

                .dashboard_rank_cnt_sc {
                  width: 30%;
                  height: auto;
                  float: left;

                  .dashboard_rank_box {
                    width: 50%;
                    height: auto;
                    float: left;
                    text-align: center;

                    &:nth-child(2) .dashboard_rank_box_rnd {
                      background-color: #F2F2D8;
                    }

                    .dashboard_rank_box_rnd {
                      width: 50px;
                      height: 50px;
                      background-color: #D9EFF4;
                      text-align: center;
                      line-height: 56px;
                      color: #242424;
                      font-size: 14px;
                      display: inline-block;
                      border-radius: 50%;

                    }

                    span {
                      width: 100%;
                      height: auto;
                      float: left;
                      font-size: 13px;
                      white-space: nowrap;
                      color: #4B4A4A;
                    }
                  }
                }

              }
            }
          }
        }

        .dashbord_earning_expence {
          width: 100%;
          height: auto;
          float: left;
          margin-top: 15px;
          grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
          gap: 1%;
          display: grid;

          .dashbrd_earing_exp_sec {
            width: 100%;
            height: auto;
            float: left;
          }

          .dashbord_earning_expence_cnt_sc {
            width: 100%;
            height: auto;
            float: left;
            margin-top: 10px;

            .earning_tabs_row {
              width: 100%;
              height: auto;
              float: left;
              margin-bottom: 10px;
              padding-bottom: 8px;
              border-bottom: 1px #e5e5e5 solid;

              &:last-child {
                border: 0;
              }

              .earning_tabs_name {
                width: 70%;
                height: auto;
                float: left;
                color: #565656;
                font-size: 16px;

                span {
                  color: #03AD47;
                  font-size: 14px;
                  float: right;
                  min-width: 80px !important;
                }
              }

              .earning_right_ico {
                width: 30%;
                float: right;
                height: auto;
                text-align: center;

                span {
                  width: 30px;
                  height: 30px;
                  float: right;
                  background: #70A9FF;
                  border-radius: 50%;
                  text-align: center;
                  line-height: 30px;
                  color: #fff;
                  font-size: 13px;
                  margin-top: -5px;
                }
              }
            }
          }

          .dash_perfomencers {
            width: 100%;
            height: auto;
            float: left;
            margin-top: 10px;

            .dash_perfomencers_row {
              width: 100%;
              height: auto;
              float: left;
              margin-bottom: 8px;
              border-bottom: 1px #e5e5e5 solid;
              padding-bottom: 5px;
              position: relative;
              padding-left: 45px;

              .dash_perfomencers_prf_ico {
                width: 30px;
                height: 30px;
                position: absolute;
                left: 0;
                top: 0;
                background-color: #ccc;
                overflow: hidden;
                border-radius: 50%;
              }

              .dash_perfomencers_name {
                width: 70%;
                height: auto;
                float: left;
                color: #6D617C;
                font-size: 16px;
                margin-bottom: 3px;

                span {
                  width: 100%;
                  height: auto;
                  float: left;
                  color: #a1a1a1;
                  font-size: 13px;
                }
              }

              .dash_perfomencers_amnt {
                width: 30%;
                height: auto;
                float: right;
                font-size: 16px;
                color: #656565;
                text-align: right;
              }
            }
          }

        }

      }

      /**main_cnt*/

    }
  }
}

.left_navigation_fold {
  .cntr_main_cnt_sc {
    padding-left: 2px !important;
  }

  //padding-left: 100px !important; }
  .left_navigation_sc {
    width: 80px !important;

    .submenu {
      width: 150px !important;
      height: auto !important;
      position: absolute !important;
      left: 60px !important;
      top: 30px !important;
      background-color: #fff !important;
      // display: none !important;
      transition: opacity 0s ease-out !important;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);

      li {
        margin-bottom: 5px !important;
      }
    }

    li {
      margin-bottom: 30px !important;

      strong {
        display: none !important;
      }

      a {
        .material-icons-outlined {
          position: static !important;
        }
      }

      &:hover strong {
        display: block !important;
        position: absolute !important;
        left: 24px !important;
        width: 150px !important;
        padding-left: 20px !important;
        background-color: #fff !important;
        box-shadow: 0px -12px 15px rgba(0, 0, 0, 0.2);
        z-index: 10 !important;
      }
    }

    .arrow_mn {
      display: none !important;
    }

    .active {
      a {
        margin-bottom: 0 !important;

        strong {
          left: 35px !important;
          top: 15px !important;
        }

        span {
          width: 60px !important;
          height: 50px !important;
          line-height: 50px !important;

          &:after {
            left: 14px !important;
            top: 45px !important;
          }
        }
      }
    }
  }

}

.lst_box_quick_select {
  width: 100%;
  height: auto;
  float: left;

  .lst_box_quick_select_btn {
    position: absolute;
    width: 4px;
    font-size: 22px;
    color: #665f5f;
    right: 23px;
    top: -3px;
    line-height: 6px;
    word-break: break-word;
    padding: 6px 15px;
    background-color: transparent;
    border: 0;
    z-index: 8;
  }

}

.show {
  .dropdownmenu {
    display: block !important;
    right: 10px;
    top: 33px;
  }
}

.dropdownitem {
  font-size: 14px;
}

.dash_perfomencers_amnt span {
  width: 30px;
  height: 30px;
  float: right;
  background: #70A9FF;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-size: 13px;
  margin-top: 0px;
}


@media (max-width:1500px) {
  .main_contant_sec .dashboard_rank_cnt_sc {
    width: 40% !important;
  }

  .main_contant_sec .dashboard_rank {
    width: 60% !important;
  }
}

@media (max-width:1386px) {
  .resp-tabs-list li {
    padding: 5px 7px !important;
  }

  .listing_box_cnout {
    font-size: 19px !important;
  }

  // .left_navigation_fold .cntr_main_cnt_sc { padding-left: 70px !important;}
  .listing_box_sec {
    padding: 10px !important;
  }

  .dashboard_rank_box span {
    font-size: 12px !important;
  }

  .listing_box_ico_rnd {
    margin-top: 10px !important;
  }
}

@media (max-width:1265px) {
  .profilesoc_share_bx_ico {
    width: 38px !important;
    height: 38px !important;
    line-height: 28px !important;
    margin-top: 1px !important;
  }

  .mid_flll_wdth {
    width: 100% !important;
  }

  .list_boxes_main {
    margin-bottom: 15px;
    height: auto !important;
  }

  .dash_member_join_rank_sec {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important;
    gap: 0 !important
  }

  .dashbord_earning_expence {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important;
    gap: 0 !important
  }

  .dashboard_pannel_box {
    height: auto !important;
  }

  .dashbord_earning_expence {
    margin-top: 0 !important;
  }

  .dash_member_join_rank_sec {
    margin-top: 0 !important;
  }

  .list_boxes_main {
    margin-bottom: 0 !important;
  }
}

@media (max-width:991px) {
  .dashboard_main_cnt {
    padding: 20px !important;
  }

  // .contant_sec{margin-left: 190px !important;}
  .profile_other_box {
    border: 0 !important;
  }

  .profile_main_sec {
    border: 0 !important;
  }

  .profile_soc_share_sec {
    border: 0 !important;
  }

}

@media (max-width:768px) {
  .main_contant_sec .boxes_lst_sec {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important;
  }

  .listing_box_sec {
    text-align: center;
  }

  .listing_box_ico_rnd {
    display: inline-block;
    float: none !important;
  }

  .menubar-toggle-btn {
    display: none;
  }

  .notification_msg_box {
    z-index: 99 !important;
    right: -90px !important;
  }

  .sidebar>.nav>.nav-item {
    position: relative;
  }
}

@media (max-width:500px) {
  .top_notification_sec {
    width: 90px !important;
    padding-right: 0;
  }

  .top_notification_ico {
    margin-right: 13% !important;
  }

  .profile_main_sec {
    padding-left: 0px !important;
    padding-top: 80px;
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px #e5e5e5;
  }

  .profile_main_img_bx {
    width: 70px !important;
    ;
    height: 70px !important;
    ;
    left: 0px !important;
    right: 0;
    margin: auto;
  }

  .topbar_section .logo_sec {
    width: 100px !important;
  }

  .top_user_acc_sec button {
    font-size: 0;
  }

  .top_user_acc_sec button span {
    font-size: 15px;
  }

  .profilesoc_share_bx {
    width: 100% !important;
    text-align: center;
  }

  .dashboard_main_cnt {
    padding: 10px !important;
  }

  .dashboard_pannel_box {
    padding: 10px !important;
  }

  .dashboard_new_member_id_date {
    width: 100% !important;
  }

  .dashboard_new_member_name {
    width: 100% !important;
  }

  .dashboard_new_member_date_sc {
    width: 100% !important;
    text-align: left !important;
  }

  .dashboard_new_member_amnt {
    width: 100% !important;
    text-align: left !important;
  }

  .dashboard_new_member_name {
    font-weight: 600 !important;
  }

  .spouncer_cnt_boxes {
    width: 49% !important;
  }

  .earning_tabs_name span {
    float: left !important;
    width: 100%;
    position: static;
  }

  .main_contant_sec .dashboard_rank {
    width: 97% !important;
  }

  .main_contant_sec .dashboard_rank_cnt_sc {
    width: 100% !important;
    margin-top: 14px !important;
  }

  h2.resp-accordion {
    border: 0 !important;
    background-color: transparent !important;
  }

  .dash_perfomencers_name {
    width: 100% !important;
  }

  .dash_perfomencers_amnt {
    width: 100% !important;
    text-align: left !important;
  }

  .dash_perfomencers_name span {
    font-size: 13px
  }

  h2.resp-accordion {
    padding: 12px 5px;
  }

  .profilesoc_share_bx_ico {
    float: none !important;
    display: inline-block;
  }

  .profile_other_box_cnt {
    width: 48% !important;
    margin-left: 2%;
    text-align: center;
  }

  .sponcer_sec {
    margin-top: 70px;
  }

  .dashboard_rank_box_rnd {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important
  }

  .profilesoc_share_bx_row {
    display: flex;
    justify-content: center;
    gap: 5px
  }

  .profile_other_box_cnt {
    width: 100% !important;
    text-align: center;
  }

  .profilesoc_share_bx_hd {
    width: 100% !important;
    text-align: center;
  }
}

.pydata {
  position: absolute;
  right: 5%;
}

;



/* DARK MODE STARTING POINT */
.dashboard_main_cnt {
  width: 100%;
  height: auto;
  float: left;
  padding: 20px 0px;

  .dark_mode_dashboard_pannel_box {
    width: 100%;
    height: 90%;
    float: left;
    padding: 20px;
    background: #112237;
    box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    position: relative;
    margin-bottom: 15px;

    &:hover {
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.18);
    }

    .profile_main_sec {
      width: 100%;
      height: 100%;
      float: left;
      padding-left: 130px;
      border-right: 1px #e5e5e5 solid;
      position: relative;

      .profile_main_img_bx {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #fff;
        border: 5px solid #FFFFFF;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.15));
        overflow: hidden;
        position: absolute;
        left: 10px;
        top: 0px;

        img {
          width: 100%;
          flex-shrink: 0;
          min-width: 100%;
          min-height: 100%;
          ;
        }
      }

      .dark_mode_profile_main_txt_sc {
        width: 100%;
        height: auto;
        float: left;

        strong {
          width: 100%;
          height: auto;
          float: left;
          font-size: 22px;
          color: #fff;
          margin-bottom: 5px;
          overflow-wrap: break-word;
        }

        span {
          width: 100%;
          height: auto;
          float: left;
          font-size: 14px;
          color: #868686;
          margin-bottom: 5px;
        }

        .dark_mode_view_prfl_btn {
          width: 120px;
          // height: 30px;
          display: inline-block;
          filter: drop-shadow(0px 5px 14px rgba(86, 85, 88, 0.2));
          border-radius: 60px;
          border: 0.5px solid #bebebe;
          text-align: center;
          line-height: 28px;
          font-size: 14px;
          color: #fff;
          text-decoration: none;
          margin-top: 8px;
          transition: 0.2s ease;

          &:hover {
            background-color: #363636;
            color: #fff;
          }
        }
      }
    }

    .profile_soc_share_sec {
      width: 100%;
      height: 100%;
      float: left;
      border-right: 1px #e5e5e5 solid;
      position: relative;
      padding-top: 8px;
      padding-bottom: 15px;

      .profilesoc_share_bx {
        width: 50%;
        height: auto;
        float: left;
        margin-top: 10px;

        .dark_mode_profilesoc_share_bx_hd {
          width: 100%;
          height: auto;
          float: left;
          font-size: 14px;
          color: #FFFFFF !important;
        }

        .dark_mode_profilesoc_share_bx_hd span {
          color: #ffffff !important;
        }

        .dark_mode_profilesoc_share_bx_row {
          width: 100%;
          height: auto;
          float: left;
          margin-top: 10px;

          a {
            color: white !important;
          }

          .dark_mode_profilesoc_share_bx_ico {
            width: 35px;
            height: 35px;
            line-height: 30px;
            margin-right: 2%;
            text-align: center;
            float: left;
            background: #344256 !important;
            border-radius: 50%;
            padding-top: 5px;
            transition: 0.2s ease;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);

            &:hover {
              box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.35);
            }

            img {
              width: 15px;
            }

            @media (max-width: 768px) {
              width: 35px;
              height: 35px;
              line-height: 30px;
              margin-right: 2%;
              text-align: center;
              float: left;
              background: #344256 !important;
              border-radius: 50%;
              padding-top: 5px;
              transition: 0.2s ease;
              box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.17);

              &:hover {
                box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.35);
              }

              img {
                width: 15px;
              }
            }
          }

        }
      }
    }

    .dark_mode_profile_other_box {
      width: 100%;
      height: 100%;
      float: left;
      border-right: 1px #ffffff solid !important;
      padding-right: 10px;
      position: relative;

      .dark_mode_profile_other_box_cnt {
        width: 100%;
        height: auto;
        float: left;
        color: white !important;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 10px;

        strong {
          width: 100%;
          height: auto;
          float: left;
          color: white !important;
          font-size: 16px;
        }
      }
    }



  }

  /**panel*/


  .dashboard_boxs_listing_sponcer_sec {
    width: 100%;
    height: auto;
    float: left;

    .lst_box_quick_select {
      width: 100%;
      height: auto;
      float: left;

      .lst_box_quick_select_btn {
        position: absolute;
        width: 4px;
        font-size: 22px;
        color: #665f5f;
        right: 23px;
        top: 3px;
        line-height: 6px;
        word-break: break-word;
        padding: 6px 15px;
        background-color: transparent;
        border: 0;
        z-index: 8;
        // transform: rotate(-91deg);

      }

    }

    .show {
      .dropdownmenu {
        display: block !important;
        right: 10px;
        top: 33px;
      }
    }

    .boxes_lst_sec {
      grid-template-columns: repeat(auto-fit, minmax(15%, 1fr));
      display: grid;
      height: 100%;
      gap: 2%;

      .dark_mode_dashboard_pannel_box {
        padding: 0;
      }
    }

    .list_boxes_main {
      width: 100%;
      height: 100%;
      float: left;
      cursor: pointer;
    }

    .list_boxes_main:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, .2);
    }


    .dark_mode_dashboard_pannel_box {
      // box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.07);
      height: 100%;
      border-radius: 10px;
    }

    .listing_box_sec {
      width: 100%;
      height: 100%;
      float: left;
      // min-height: 230px;
      padding: 20px;
      overflow: hidden;
      position: relative;

      &::after {
        width: 150px;
        height: 150px;
        position: absolute;
        right: -70px;
        bottom: -70px;
        content: '';
        background: #FFE3E3;
        opacity: 0.6;
        border-radius: 120px;
        display: none;

      }

      .listing_box_ico_rnd {
        width: 80px;
        height: 70px;
        float: left;
        background: #DF3B3B;
        border-radius: 20px;
        margin-bottom: 20px;
        text-align: center;
        line-height: 60px;
        position: relative;

        &::after {
          width: 50px;
          height: 50px;
          position: absolute;
          left: 20px;
          bottom: -23px;
          content: '';
          background: #E75D5D;
          opacity: 0.6;
          -webkit-filter: blur(24px);
          filter: blur(19px);
          border-radius: 5px;
        }

        img {
          width: 50%;
        }
      }

      .dark_mode_listing_box_head {
        width: 100%;
        height: auto;
        float: left;
        color: #ffffff;
        font-size: 16px;
        margin-top: 10px;
        line-height: 22px;
        position: relative;
        z-index: 1;
        margin-bottom: 0px;
        min-height: 44px;

      }

      .listing_box_cnout {
        width: 100%;
        height: auto;
        float: left;
        font-weight: bold;
        font-size: 21px;
        font-family: 'poppinsmedium';
        color: #DF3B3B;
        // position: relative;
        position: absolute;
        bottom: 10%;
        z-index: 1;
        // &::after{
        //   width: 3px;
        //   height: 20px;
        //   background-color: #DF3B3B;
        //   content: '';
        //   position: absolute;
        //   left: -20px;
        //   top: 6px
        // }
      }


    }

    .lst_box2 {
      .listing_box_ico_rnd {
        background: #68CADF;

        &::after {
          background: #6ac8dd;
        }
      }

      // .listing_box_sec {
      //   &::after {
      //     background: #D8F8FF;
      //   }
      // }

      .listing_box_cnout {
        color: #68CADF;

        &::after {
          background-color: #68CADF;
        }
      }
    }

    .lst_box3 {
      .listing_box_ico_rnd {
        background: #70A9FF;

        &::after {
          background: #8AB9FF;
        }
      }

      .listing_box_sec {
        &::after {
          background: #DFECFF;
        }
      }

      .listing_box_cnout {
        color: #70A9FF;

        &::after {
          background-color: #70A9FF;
        }
      }
    }

    .lst_box4 {
      .listing_box_ico_rnd {
        background: #F273FD;

        &::after {
          background: #F273FD;
        }
      }

      .listing_box_sec {
        &::after {
          background: #FDE6FF;
        }
      }

      .listing_box_cnout {
        color: #F273FD;

        &::after {
          background-color: #F273FD;
        }
      }
    }


    .sponcer_sec {
      width: 100%;
      height: 100%;
      float: left;

      .dark_mode_sponcer_top_name_sec {
        width: 100%;
        height: auto;
        float: left;
        position: relative;
        padding-right: 50px;

        .sponcer_top_ico {
          width: 50px;
          height: auto;
          position: absolute;
          right: 10px;
          top: 10px;
        }

        .dark_mode_sponcer_top_name_txt {
          width: 100%;
          height: auto;
          float: left;
          color: #FFFFFF;
          font-size: 22px;
          margin-top: 5px;

          span {
            width: 100%;
            height: auto;
            float: left;
            color: #FFFFFF;
            font-size: 15px;
            margin-top: 3px;
          }
        }
      }

      .spouncer_cnt_row {
        width: 100%;
        height: auto;
        float: left;
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        align-items: self-end;

        .dark_mode_spouncer_cnt_boxes {
          width: 24%;
          height: auto;
          float: left;
          margin-right: 1%;
          color: white;
          font-size: 13px;
          margin-bottom: 5px;

          strong {
            width: 100%;
            height: auto;
            float: left;
            color: white;
            font-size: 18px;
          }
        }
      }
    }


  }

  .dash_member_join_rank_sec {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 20px;
    grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
    gap: 1%;
    display: grid;

    .dashboard_new_member_sec {
      width: 100%;
      height: auto;
      float: left;

      .dark_mode_dashboard_new_member_head {
        width: 100%;
        height: auto;
        float: left;
        color: #fff !important;
        font-size: 17px;
        padding-bottom: 15px;
      }

      .dashboard_new_member_cnt_scrl {
        width: 100%;
        height: 300px;
        overflow: auto;
        float: left;

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5;
        }

        &::-webkit-scrollbar {
          width: 10px;
          background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #000000;
          border: 2px solid #555555;
        }
      }

      .dashboard_new_member_row {
        width: 100%;
        height: auto;
        float: left;
        margin-bottom: 8px;
        border-bottom: 1px #e5e5e5 solid;
        padding-bottom: 5px;

        &:last-child {
          border-bottom: 0;
        }

        .dashboard_new_member_sub_row {
          width: 100%;
          height: auto;
          float: left;

          .dark_mode_dashboard_new_member_name {
            width: 70%;
            height: auto;
            float: left;
            color: #fff;
            font-size: 16px;
            margin-bottom: 3px;

          }

          .dark_mode_dashboard_new_member_amnt {
            width: 30%;
            height: auto;
            float: right;
            font-size: 16px;
            color: #CFCECF;
            text-align: right;
          }

          .dark_mode_dashboard_new_member_id_date {
            width: 70%;
            height: auto;
            float: left;
            font-size: 13px;
            color: #CFCECF;

          }

          .dark_mode_dashboard_new_member_date_sc {
            width: 30%;
            height: auto;
            float: right;
            font-size: 13px;
            color: #CFCECF;
            text-align: right;
          }
        }

      }
    }

    .dashboard_joining_sec {
      width: 100%;
      height: auto;
      float: left;

      .dark_mode_dashboard_newsec_head {
        width: 100%;
        height: auto;
        float: left;
        color: #fff;
        font-size: 17px;
        padding-bottom: 15px;
      }

      .dashboard_newsec_grph_sec {
        width: 100%;
        height: 300px;
        float: left;
      }
    }

    .dashboard_rank_sec {
      width: 100%;
      height: auto;
      float: left;

      .dashboard_rank_scrl_sec {
        width: 100%;
        height: 350px;
        overflow: auto;
        float: left;

        .dashboard_rank_row {
          width: 100%;
          height: auto;
          float: left;
          margin-bottom: 5px;
          padding-bottom: 18px;
          border-bottom: 1px #e5e5e5 solid;

          .dark_mode_dashboard_rank_row_head {
            width: 100%;
            height: auto;
            float: left;
            font-size: 14px;
            margin-bottom: -10px;
            color: white;

            span {
              color: #2855BF;
            }
          }

          .dashboard_rank {
            width: 70%;
            height: auto;
            float: left;
            position: relative;
            padding-left: 40px;
            margin-top: 18px;

            .dashboard_rank_ico {
              width: 35px;
              height: 35px;
              border-radius: 10px;
              background: linear-gradient(180deg, #75df68 0%, #2D70BE 100%);
              text-align: center;
              line-height: 30px;
              position: absolute;
              left: 0;
              top: 2px;
            }

            .dashboard_rank_hd {
              width: 100%;
              height: auto;
              float: left;
              font-size: 13px;
              color: #868686;
              margin-bottom: 5px;
            }

            .dashboard_rank_prg_bar {
              width: 100%;
              height: auto;
              float: left;
              font-size: 13px;
              color: #868686;
              padding-right: 30px;
              position: relative;

              .dark_mode_dashboard_rank_prg_bar_cnt {
                width: 25px;
                height: auto;
                position: absolute;
                right: 10px;
                color: #fff;
                font-size: 13px;
                top: -6px;
              }

              .progress {
                width: 94%;
                height: 5px;
                border-radius: 23px;
              }

              .progress_wrap {
                background: #ddd;
                margin: 0px 0;
                overflow: hidden;
                position: relative;

                .progress_bar {
                  background: #004073;
                  left: 0;
                  position: absolute;
                  top: 0;
                }
              }

            }
          }

          .dashboard_rank_cnt_sc {
            width: 30%;
            height: auto;
            float: left;

            .dashboard_rank_box {
              width: 50%;
              height: auto;
              float: left;
              text-align: center;

              &:nth-child(2) .dark_mode_dashboard_rank_box_rnd {
                background-color: #6E7885;
              }

              .dark_mode_dashboard_rank_box_rnd {
                width: 50px;
                height: 50px;
                background-color: #6E7885;
                text-align: center;
                line-height: 56px;
                color: #fff !important;
                font-size: 14px;
                display: inline-block;
                border-radius: 50%;

                span {
                  width: 100%;
                  height: auto;
                  float: left;
                  font-size: 13px;
                  white-space: nowrap;
                  color: #fff !important;
                }

              }

              span {
                width: 100%;
                height: auto;
                float: left;
                font-size: 13px;
                white-space: nowrap;
                //color: #fff !important;
              }
            }
          }

        }
      }
    }
  }

  .dashbord_earning_expence {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 15px;
    grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
    gap: 1%;
    display: grid;

    .dashbrd_earing_exp_sec {
      width: 100%;
      height: auto;
      float: left;
    }

    .dashbord_earning_expence_cnt_sc {
      width: 100%;
      height: auto;
      float: left;
      margin-top: 10px;

      .earning_tabs_row {
        width: 100%;
        height: auto;
        float: left;
        margin-bottom: 10px;
        padding-bottom: 8px;
        border-bottom: 1px #e5e5e5 solid;

        &:last-child {
          border: 0;
        }

        .dark_mode_earning_tabs_name {
          width: 70%;
          height: auto;
          float: left;
          color: #FFFFFF;
          font-size: 16px;

          span {
            color: #CCD5CF;
            font-size: 14px;
            float: right;
            min-width: 80px !important;
          }
        }

        .earning_right_ico {
          width: 30%;
          float: right;
          height: auto;
          text-align: center;

          span {
            width: 30px;
            height: 30px;
            float: right;
            background: #70A9FF;
            border-radius: 50%;
            text-align: center;
            line-height: 30px;
            color: #fff;
            font-size: 13px;
            margin-top: -5px;
          }
        }
      }
    }

    .dash_perfomencers {
      width: 100%;
      height: auto;
      float: left;
      margin-top: 10px;

      .dash_perfomencers_row {
        width: 100%;
        height: auto;
        float: left;
        margin-bottom: 8px;
        border-bottom: 1px #e5e5e5 solid;
        padding-bottom: 5px;
        position: relative;
        padding-left: 45px;

        .dash_perfomencers_prf_ico {
          width: 30px;
          height: 30px;
          position: absolute;
          left: 0;
          top: 0;
          background-color: #ccc;
          overflow: hidden;
          border-radius: 50%;
        }

        .dark_mode_dash_perfomencers_name {
          width: 70%;
          height: auto;
          float: left;
          color: #FFF;
          font-size: 16px;
          margin-bottom: 3px;

          span {
            width: 100%;
            height: auto;
            float: left;
            color: #a1a1a1;
            font-size: 13px;
          }
        }

        .dark_mode_dash_perfomencers_amnt {
          width: 30%;
          height: auto;
          float: right;
          font-size: 16px;
          color: #FFFFFF;
          text-align: right;
        }
      }
    }

  }

  .image_Preview {

    max-width: 20%;
    max-height: 20%;


  }


}

.dashboard_tiles_Second_lst{
  width: 100%;
  .listing_box_ico_rnd{
    display: none;
  }
}

/* DARK MODE ENDING POINT */

.tailHeading{
  color: #666 !important;
  font-size: 12px;
}